// eslint-disable-next-line no-restricted-imports
import type { LinkProps as NextLinkProps } from 'next/link'
// eslint-disable-next-line no-restricted-imports
import NextLink from 'next/link'
import { forwardRef, type ComponentPropsWithoutRef } from 'react'

import { useCurrentLocale } from '../../translations/use-current-locale'
import type { LanguageCode } from '../../helpers/i18n'

import { addPathLocalePrefix, setLocaleCookie } from './i18n-utils'

const prefixHref = (href: NextLinkProps['href'], locale: LanguageCode | false) => {
  if (typeof href === 'string') {
    return addPathLocalePrefix({ pathname: href, locale })
  }

  if (!href.pathname) {
    return href
  }

  return { ...href, pathname: addPathLocalePrefix({ pathname: href.pathname, locale }) }
}

export type LinkProps = Omit<ComponentPropsWithoutRef<typeof NextLink>, 'as' | 'locale'> & {
  locale?: LanguageCode | false
}

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ children, href, locale, onClick, ...rest }, forwardedRef) => {
    const currentLocale = useCurrentLocale()
    const targetLocale = locale ?? currentLocale
    const prefixedHref = prefixHref(href, targetLocale)

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (targetLocale && targetLocale !== currentLocale) {
        setLocaleCookie(targetLocale)
      }

      onClick?.(event)
    }

    return (
      <NextLink ref={forwardedRef} onClick={handleClick} href={prefixedHref} {...rest}>
        {children}
      </NextLink>
    )
  },
)
