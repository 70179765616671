import type {
  NavigateOptions as NextNavigateOptions,
  PrefetchOptions as NextPrefetchOptions,
} from 'next/dist/shared/lib/app-router-context.shared-runtime'
// eslint-disable-next-line no-restricted-imports
import { useRouter as useNextRouter } from 'next/navigation'
import { useCallback, useMemo } from 'react'

import type { LanguageCode } from '../../helpers/i18n'
import { useCurrentLocale } from '../../translations/use-current-locale'

import { addPathLocalePrefix, setLocaleCookie } from './i18n-utils'

type NavigateOptions = NextNavigateOptions & {
  /**
   * Allows to navigate to a different locale from the active one.
   * If `false`, it will navigate without a locale prefix.
   */
  locale?: LanguageCode | false
}

type PrefetchOptions = NextPrefetchOptions & {
  /**
   * Allows to prefetch a different locale from the active one.
   * If `false`, the prefetch will be done without a locale prefix.
   */
  locale?: LanguageCode | false
}

/**
 * A wrapper around `useRouter` from `next/navigation` that adds the locale prefix to the pathname.
 *
 * Read more: [Next.js Docs: `useRouter`](https://nextjs.org/docs/app/api-reference/functions/use-router)
 */
export const useRouter = () => {
  const router = useNextRouter()
  const currentLocale = useCurrentLocale()

  const push = useCallback(
    (href: string, options?: NavigateOptions) => {
      const { locale, ...restOptions } = options ?? {}

      const targetLocale = locale ?? currentLocale

      if (targetLocale && targetLocale !== currentLocale) {
        setLocaleCookie(targetLocale)
      }

      const newHref = addPathLocalePrefix({ pathname: href, locale: targetLocale })
      // Makes testing easier if we don't pass the options object if it's empty
      // since we don't have to check that it was called with a second argument
      const passedOptions = Object.keys(restOptions).length > 0 ? [restOptions] : []

      router.push(newHref, ...passedOptions)
    },
    [currentLocale, router],
  )

  const replace = useCallback(
    (href: string, options?: NavigateOptions) => {
      const { locale: newLocale, ...restOptions } = options ?? {}

      const targetLocale = newLocale ?? currentLocale

      if (targetLocale && targetLocale !== currentLocale) {
        setLocaleCookie(targetLocale)
      }

      const newHref = addPathLocalePrefix({ pathname: href, locale: targetLocale })
      // Makes testing easier if we don't pass the options object if it's empty
      // since we don't have to check that it was called with a second argument
      const passedOptions = Object.keys(restOptions).length > 0 ? [restOptions] : []

      router.replace(newHref, ...passedOptions)
    },
    [currentLocale, router],
  )

  const prefetch = useCallback(
    (href: string, options?: PrefetchOptions) => {
      const { locale: newLocale, ...restOptions } = options ?? {}

      const targetLocale = newLocale ?? currentLocale

      // To make TS happy since we don't want to pass the options object if it's empty
      const passedOptions = Object.keys(restOptions).length > 0 ? (restOptions as PrefetchOptions) : undefined
      const newHref = addPathLocalePrefix({ pathname: href, locale: targetLocale })

      router.prefetch(newHref, passedOptions)
    },
    [currentLocale, router],
  )

  return useMemo(() => {
    return {
      ...router,
      push,
      replace,
      prefetch,
    }
  }, [router, push, replace, prefetch])
}
