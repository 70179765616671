import Cookies from 'js-cookie'

import { CookieNames } from '../../helpers/cookie'
import type { LanguageCode } from '../../helpers/i18n'

const pathHasLocalePrefix = (pathname: string, locale: LanguageCode) => {
  return pathname === `/${locale}` || pathname.startsWith(`/${locale}/`)
}

type AddLocalPrefixToPathnameParams = {
  pathname: string
  locale: LanguageCode | false
}

export const addPathLocalePrefix = ({ pathname, locale }: AddLocalPrefixToPathnameParams) => {
  if (!locale) {
    return pathname
  }

  if (!pathname.startsWith('/')) {
    return pathname
  }

  if (pathHasLocalePrefix(pathname, locale)) {
    return pathname
  }

  return `/${locale}${pathname}`
}

export const removePathLocalePrefix = (pathname: string, locale: LanguageCode) => {
  if (!pathHasLocalePrefix(pathname, locale)) {
    return pathname
  }

  const withoutPrefix = pathname.slice(locale.length + 1)

  // If the path without the prefix starts with a `/` we can return it as is.
  if (withoutPrefix.startsWith('/')) {
    return withoutPrefix
  }

  // If the path without the prefix doesn't start with a `/` we need to add it
  // back to the path to make sure it's a valid path.
  return `/${withoutPrefix}`
}

export const setLocaleCookie = (locale: LanguageCode) => {
  Cookies.set(CookieNames.SELECTED_LANGUAGE, locale, { expires: 365 })
}
