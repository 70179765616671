// eslint-disable-next-line no-restricted-imports
import { usePathname as useNextPathname } from 'next/navigation'

import { useCurrentLocale } from '../../translations/use-current-locale'

import { removePathLocalePrefix } from './i18n-utils'

/**
 * A wrapper around `usePathname` from `next/navigation` that removes the locale prefix from the pathname.
 *
 * Read more: [Next.js Docs: `usePathname`](https://nextjs.org/docs/app/api-reference/functions/use-pathname)
 *
 */
export const usePathname = () => {
  const pathname = useNextPathname()
  const locale = useCurrentLocale()

  return removePathLocalePrefix(pathname, locale)
}
