import { useCurrentLocale as ogUseCurrentLocale } from 'next-i18n-router/client'

import { i18nConfig } from '../../i18nConfig'
import type { LanguageCode } from '../helpers/i18n'

export const useCurrentLocale = () => {
  const locale = ogUseCurrentLocale(i18nConfig) as LanguageCode

  return locale
}
